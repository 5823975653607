import React, { useEffect, useState } from "react"
import DefaultTemplate from "@src/site/templates/default"
import { makeStyles } from "@material-ui/core/styles"
import { window, document } from "browser-monads"
import Text from "@src/components/Text"
import cookie from "@src/utils/cookie"
import { Grid } from "@material-ui/core"
import Hidden from "@material-ui/core/Hidden"
import countries from "../site/countries"
import Icon from "../components/Icon"

// Images
import mobile from "@src/assets/img/redirect_page/mobile.png"
import mobilex2 from "@src/assets/img/redirect_page/mobilex2.png"
import cover from "@src/assets/img/redirect_page/cover-switch.jpg"
import logo from "@src/assets/img/logo_vertical/logo-fh.svg"
import { detectBrowserLanguage } from "../utils/country"

const useStyles = makeStyles(theme => ({
  background: {
    background: "#fff",
    backgroundImage: `url(${cover})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
    },
  },
  page: {
    height: "100vh",
  },
  mobileBlock: {
    width: "100%",
  },
  centralBlock: {
    position: "relative",
    maxWidth: 340,
    zIndex: 9,
    top: -80,
    padding: "1rem",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      top: "50%",
      transform: "translateY(-50%)",
      padding: "auto",
    },
  },
  title: {
    color: theme.customPalette.darkGrey,
    marginBottom: theme.spacing(4),
    textAlign: "center",
    fontSize: "18px !important",
    fontFamily: "Poppins",
  },
  formControl: {
    width: "100%",
    maxWidth: "600px",
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  label: {
    color: theme.customPalette.black,
  },
  extSelect: {
    backgroundColor: "#ffffff",
    width: "100%",
  },
  select: {
    width: "100%",
    "& select": {
      fontSize: "14px",
      height: "20px",
      paddingTop: "9px!important",
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: "24px",
    width: "100%",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
    },
  },
  logo: {
    margin: "0 auto 23px auto",
    display: "block",
    cursor: "pointer",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    textIndent: "-9999px",
    width: "100%",
    maxWidth: "184px",
    height: "110px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "112px",
      height: "100px",
    },
  },
  choose: {
    border: "1px solid #BCBCBC",
    borderRadius: 3,
    padding: "20px 20px 10px 20px",
  },

  country: {
    maxWidth: "100%",
    width: 220,
    padding: "10px 15px",
    marginBottom: 10,
    textDecoration: "none",
    borderRadius: 6,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",

    "& .flagTextContainer": {
      display: "flex",
      justifyContent: "center",
    },

    "& .countryFlag": {
      marginRight: 15,
      "& .flag": {
        height: 25,
        width: 35,
        border: "1px solid #B4BAC0",
        overflow: "hidden",
        borderRadius: 3,
        "& img": {
          boxShadow: "0px 0px 14px 0px rgba(102, 102, 102, 0.37)",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        },
      },
    },

    "& .countryText": {
      paddingRight: 15,
      display: "flex",
      "& .text": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: 12,
        fontweight: 600,
        color: theme.customPalette.darkGrey,
      },
    },

    "& .countryArrow": {
      "& .arrow": {
        display: "none",
        width: 22,
        height: "auto",
      },
    },

    "&:hover, &.active": {
      backgroundColor: "#D5CCDC",
      color: "#5D2B7D",
    },

    "&:hover .arrow, &.active .arrow": {
      display: "block !important",
      margin: "0 0 auto auto",
    },
  },
}))

export default function IndexPage() {
  const classes = useStyles()
  const [sC, setSc] = useState("")
  const cookieNameOfSelectedCountry = "cookie_country"

  const setAlternates = () => {
    var values = [{ href: "https://www.fanhome.com/", hreflang: "x-default" }]
    for (let i = 0; i < countries.length; i++) {
      values.push({ href: countries[i].href, hreflang: countries[i].hreflang })
    }
    return values
  }

  const page = {
    content: null,
    seo: {
      title: "Fanhome - Welcome to Fanhome",
      desc: "Our goal is to make every collection an awesome experience. Immerse yourself into the universes you love and be inspired by the exclusive content. Enter now!",
      pathname: "/",
      alternates: setAlternates() || [],
      schemaMarkup: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": "https://www.fanhome.com/#organization",
        url: "https://www.fanhome.com/",
        logo: "https://www.fanhome.com/static/3e82a58172d089a312482ae36ebb890e/logo.png",
        legalName: "Fanhome",
        name: "Fanhome",
        description:
          "Fanhome is partnering with top entertainment, gaming and pop culture brands to give you a broader and deeper access to your favourite characters and stories. At Fanhome we are fans and passionate about pop culture, from sci-fi to movies, videogames and comics. Our international team consists of expert creators, editors and writers, product engineers, model makers, communication and marketing gurus, plus logistic masters and a friendly customer service team. Our combined efforts result in the creation of original collections, magnificent models to build, inspiring content and an outstanding experience (for you to enjoy!).",
        email: "mailto:support@fanhome.com",
        telephone: "( 33 1) 42 68 53 00",
        address: {
          "@type": "PostalAddress",
          addressLocality: "Novara",
          addressRegion: "NO",
          postalCode: "28100",
          streetAddress: "Via Giovanni da Verrazano 15 Novara - Italy",
        },
      },
    },
    tracking: {},
  }

  useEffect(() => {
    const cookieCountry = cookie.getRealCookie(cookieNameOfSelectedCountry)
      ? JSON.parse(cookie.getRealCookie(cookieNameOfSelectedCountry))
      : null

    // autoredirect
    if (cookieCountry) {
      const find =
        countries.find(country => country.code === cookieCountry.countryCode) ||
        cookieCountry.countryCode === "UK"
          ? true
          : false
      if (find) {
        if (
          cookieCountry.countryCode === "GB" ||
          cookieCountry.countryCode === "UK"
        ) {
          window.location.href = `${"/uk/" + window.location.search}`
        } else {
          window.location.href = `${
            "/" +
            cookieCountry.countryCode?.toLowerCase() +
            "/" +
            window.location.search
          }`
        }
      }
    } else {
      detectBrowserLanguage().then(data => {
        const findSc = countries.find(
          country => country?.code === data?.country
        )
          ? data?.country
          : "GB"
        setSc(findSc)
      })
    }
    document.body.classList.add(classes.background)
  }, [])

  const setAbsoluteLink = url => {
    if (url?.includes("http")) {
      return url
    }
    // TODO gestire baseDomain per l'ambiente di sviluppo
    const baseDomain = process.env.GATSBY_BASE_DOMAIN || ""
    // correzione uk
    url = url?.toLowerCase() === "gb" ? "uk" : url
    url = url?.substr(0, 1) != "/" ? "/" + url : url
    url = url?.substr(-1) != "/" ? url + "/" : url
    return baseDomain + url?.toLowerCase()
  }

  return (
    <DefaultTemplate page={page}>
      <div className={classes.page}>
        <Grid container>
          <Hidden mdUp>
            <Grid className={classes.mobileBlock} md={12}>
              <picture>
                <source media="(min-width:425px)" srcSet={mobilex2}></source>
                <img src={mobile} width="100%"></img>
              </picture>
            </Grid>
          </Hidden>
          <Grid
            md={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div className={classes.centralBlock}>
              <h1>
                <a
                  className={classes.logo}
                  style={{
                    backgroundImage: `url("${logo}")`,
                  }}
                  title="Welcome to Fanhome"
                >
                  Welcome to Fanhome
                </a>
              </h1>
              <Text
                variant="specialTitleXL"
                component="div"
                weight="600"
                className={classes.title}
              >
                Choose your country
              </Text>
              <div className={classes.choose}>
                {countries.length > 0
                  ? countries
                      .sort(function (a) {
                        if (a.code === sC) return -1
                        return 0
                      })
                      .map((item, index) => (
                        <a
                          key={index}
                          className={`
                            ${classes.country} 
                            ${item.code === sC ? "active" : ""}
                          `}
                          onClick={e => {
                            e.preventDefault()
                            // set cookie for redirect
                            cookie.setRealCookie(
                              cookieNameOfSelectedCountry,
                              JSON.stringify({
                                countryCode:
                                  item.code === "GB" ? "UK" : item.code,
                                countryName: item.country,
                              }),
                              30
                            )
                            window.location.href = setAbsoluteLink(item.code)
                          }}
                          href={setAbsoluteLink(item.code)}
                        >
                          <div className="flagTextContainer">
                            <div className="countryFlag">
                              <div className="flag">
                                <img src={item.flag} alt={item.country} />
                              </div>
                            </div>
                            <div className="countryText">
                              <Text
                                defaultSize="12"
                                tag="p"
                                font="Poppins"
                                className="text"
                              >
                                {item.country}
                              </Text>
                            </div>
                          </div>
                          <div className="countryArrow">
                            <Icon className="arrow" name="right_purple" />
                          </div>
                        </a>
                      ))
                  : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </DefaultTemplate>
  )
}
