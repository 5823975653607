import { window } from "browser-monads"

const origin = window.location.origin
const search = window.location.search

const countries = [
  {
    country: "United Kingdom",
    code: "GB",
    hreflang: "en-GB",
    href: origin + "/uk" + search,
    flag: "https://d24udp600h4lxn.cloudfront.net/static/flags/uk.svg",
  },
  {
    country: "Deutschland",
    code: "DE",
    hreflang: "de-DE",
    href: origin + "/de" + search,
    flag: "https://d24udp600h4lxn.cloudfront.net/static/flags/de.svg",
  },
  {
    country: "United States",
    code: "US",
    hreflang: "en-US",
    href: origin + "/us" + search,
    flag: "https://d24udp600h4lxn.cloudfront.net/static/flags/us.svg",
  },
  {
    country: "Austria",
    code: "AT",
    hreflang: "de-AT",
    href: origin + "/at" + search,
    flag: "https://d24udp600h4lxn.cloudfront.net/static/flags/at.svg",
  },
  {
    country: "Netherlands",
    code: "NL",
    hreflang: "nl-NL",
    href: origin + "/nl" + search,
    flag: "https://d24udp600h4lxn.cloudfront.net/static/flags/nl.svg",
  },
]

export default countries
