import { window } from 'browser-monads';

const createCookie = (cookieName, value=new Date()) => {
    window.localStorage.setItem(cookieName, value);
}

const isPresent = (cookieName) => {
    let value = window.localStorage.getItem(cookieName);
    return typeof value !== "undefined" && value !== null;
};

const getCookie = (cookieName) => {
    let value = window.localStorage.getItem(cookieName);
    return value;
}

const deleteCookie = (cookieName) => {
    window.localStorage.removeItem(cookieName);
}

const setRealCookie = (cname, cvalue = new Date(), exdays = 1) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
const getRealCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

const cookie = {
    createCookie: createCookie,
    isPresent: isPresent,
    getCookie: getCookie,
    deleteCookie: deleteCookie,
    setRealCookie: setRealCookie, 
    getRealCookie: getRealCookie
}

export default cookie;